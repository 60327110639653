import { mapActions, mapGetters } from 'vuex'
import { debounce, snakeCase } from 'lodash'

import mixins from '@/mixins'
import course from '@/mixins/course'
import validation from '@/mixins/validation'

import Draggable from 'vuedraggable'

import { courseContentSocialsTableHead } from '@/modules/courses/helpers/content-socials'

import SelectPackages from '@/components/forms/SelectPackages'

export default {
  name: 'courses-contents-socials',
  mixin: [course, mixins, validation],
  data () {
    return {
      showRemoveDialog: false,
      showActionsDialog: false,
      valid: false,
      removingItem: null,
      editItem: null,
      socials: [],
      payload: {
        name: '',
        link: '',
        statusId: '',
        typeId: '',
        sort: '',
        packages: []
      }
    }
  },
  components: {
    Draggable,
    SelectPackages
  },
  watch: {
    'list' () {
      this.socials = this.list
    }
  },
  computed: {
    ...mapGetters({
      setting: 'courses/setting',
      filterKeywords: 'contentSocials/filterKeywords',
      list: 'contentSocials/list',
      isListLoading: 'contentSocials/isListLoading',
      isLoading: 'contentSocials/isLoading',
      listLength: 'contentSocials/listLength',
      limit: 'contentSocials/limit',
      skip: 'contentSocials/skip',
      packagesList: 'coursesPackages/list',
      info: 'coursesInfo/info',
      profile: 'profile/profile'
    }),
    courseContentSocialsTableHead
  },
  created () {
    if (!this.list.length) {
      this.fetchList({ course_id: this.$route.params.course_id }).then(() => {
        this.socials = this.list
      })
    } else {
      this.socials = this.list
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'contentSocials/GET_LIST',
      remove: 'contentSocials/DELETE',
      update: 'contentSocials/UPDATE',
      create: 'contentSocials/CREATE',
      updateSort: 'contentSocials/UPDATE_SORT'
    }),

    resetValidation () {
      Object.keys(this.payload).forEach(e => {
        this.payload[e] = ''
      })
      this.showActionsDialog = false
      this.editItem = null
    },
    changeDialogActions (item = null, status) {
      this.editItem = item
      if (item) {
        Object.assign(this.payload, item)
        this.payload.packages = item.packages.data.map(e => e.id)
      } else {
        this.resetValidation()
      }
      this.showActionsDialog = status
    },
    openDialogDelete (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },
    removeListItem () {
      this.remove({
        course_id: this.$route.params.course_id,
        id: this.removingItem.id
      }).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = {}
      Object.keys(data).forEach(e => {
        formData[snakeCase(e)] = data[e]
      })
      formData.course_id = this.$route.params.course_id
      return formData
    },
    submit () {
      this.$refs.form.validate()
      this.$refs.observer.validate()
      if (this.valid) {
        if (!this.editItem) {
          this.create(this.createRequestPayload()).then(response => {
            this.$toasted.success(this.$t('success_created'))
            this.resetValidation()
          })
        } else {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
            this.resetValidation()
          })
        }
      }
    },
    sendRequest: debounce(function () {
      this.fetchList({ course_id: this.$route.params.course_id })
    }, 1000),
    changeSort: debounce(function (e) {
      if (e.hasOwnProperty('moved')) {
        const formData = {
          course_id: this.$route.params.course_id,
          socials: this.socials.map(e => e.id)
        }
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    }, 2000)
  }
}
