export function courseContentSocialsTableHead () {
  return [
    {
      text: 'Name',
      value: 'name',
      sortable: false
    },
    {
      text: 'Link',
      value: 'link',
      sortable: false
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
